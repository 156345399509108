/* Global styles */
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  padding: 0;
  margin: 0;
  user-select: none;
  box-sizing: border-box;
}

/* Login styles */
.formLogin {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 450px;
  height: fit-content;
  margin: auto;
}

.nickNameInputLogin {
  width: 100%;
  height: 45px;
  font-family: Work Sans;
  font-size: 18px;
  padding: 0 15px;
  cursor: text;
  text-align: center;
  margin-bottom: 15px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px #0f4478;
}

.nickNameInputLogin::placeholder {
  text-align: center;
}

.nickNameInputLogin:focus-visible {
  outline: none;
  text-align: center;
}

/* Finding pair styles */
.formFindingPair {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 380px;
  height: fit-content;
  margin-top: 20px;
}

.nickNameInputFindingPair {
  width: 100%;
  height: 45px;
  margin-bottom: 20px;
  font-family: Work Sans;
  font-size: 18px;
  padding: 0 15px;
  cursor: text;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px #0f4478;
}

.nickNameInputFindingPair::placeholder {
  text-align: center;
}

.nickNameInputFindingPair:focus-visible {
  outline: none;
  text-align: center;
}

.copyIcon {
  position: relative;
  top: 4px;
  right: 2px;
  display: inline;
  width: 20px;
  height: 20px;
}

.findingPairLogoImage {
  width: 140px;
}

.closeConnectionButtonFindingPair {
  width: 40px;
  height: 40px;
  color: #0f4478;
}

/* Chat styles */
.formChatSendMessageBar {
  display: flex;
  padding: 7px 8px 7px 20px;
  width: 100%;
  height: 100%;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.inputChatSendMessageBar {
  padding: 9px 12px;
  width: 98%;
  height: 49px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #9a9a9a;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500;
}

.inputChatSendMessageBar:focus-visible {
  outline: none;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.closeButtonInHeader {
  min-width: 38px;
  min-height: 38px;
  margin-right: 5px;
  color: #353535;
  cursor: pointer;
}

.sendMessageIconMessageBar {
  width: 42px;
  height: 42px;
}

.submitArrowIconChatSendMessageBar {
  position: absolute;
  top: 6px;
  left: 16px;
  z-index: 2;
  color: white;
  width: 35px;
  height: 35px;
  background-color: #2a3942;
}

/* Pop up styles  */
.logoPopUp {
  margin: auto;
  grid-area: logo;
  width: 55px;
  height: 60px;
}

/* Scrollbar styles */

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #e9e9e9;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 60, 116, 0.7);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 96, 185);
}
